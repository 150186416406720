import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginSucess from "./pages/LoginSucess";
import LoginError from "./pages/LoginError";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginSucess />} />
        <Route path="error" element={<LoginError />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
