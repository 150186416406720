import "./style.scss";
export default function Header() {
  return (
    <header>
      <div className="header-container">
        <img
          src="/logoprefshorizontal.png"
          alt="logo prefeitura de salvador"
          className="logo"
        />
      </div>
    </header>
  );
}
